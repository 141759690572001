'use client'

import { Children, isValidElement } from 'react'
import { Inline } from '@faml/faml-ui'
import styled, { css } from 'styled-components'

import type { ReactNode } from 'react'
import type { Space, AlignY, AlignX } from '@faml/faml-ui'

type Width = 'content'

type Props = {
  children: ReactNode
  alignX?: AlignX
  alignY?: AlignY
  space?: Space
}

export const Columns = ({ children, space = 'm', alignY = 'top' }: Props) => (
  <ColumnsContainer>
    <Inline space={space} alignY={alignY} isFullWidth>
      {Children.map(children, (child) => {
        if (!child) {
          return null
        }

        if (!isValidElement(child)) {
          throw new Error('One of the children is not a valid element')
        }

        if (child.type !== Column) {
          throw new Error('Columns component only allows Column to be its direct child')
        }

        return child
      })}
    </Inline>
  </ColumnsContainer>
)

const ColumnsContainer = styled.div`
  display: flex;
`

export const Column = styled.div<{ $width?: Width; $alignX?: AlignX }>`
  display: flex;
  flex-direction: column;

  ${({ $alignX }) =>
    ($alignX === 'left' && alignColumnLeft) ||
    ($alignX === 'centre' && alignColumnXCentre) ||
    ($alignX === 'right' && alignColumnRight)}

  ${({ $width }) => $width === 'content' && fullWidth}
`

const fullWidth = css`
  width: 100%;
`

const alignColumnLeft = css`
  align-items: flex-start;
`

const alignColumnXCentre = css`
  align-items: center;
`

const alignColumnRight = css`
  align-items: flex-end;
`
