'use client'

import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Grid, Text } from '@faml/faml-ui'

type Props = {
  children: ReactNode
  error: string | undefined
}

export const Field = ({ children, error }: Props) => (
  <Grid rowGap="s">
    <ErrorStyleWrapper $isError={Boolean(error)}>{children}</ErrorStyleWrapper>
    {error && (
      <Text colour="error" size="hint">
        {error}
      </Text>
    )}
  </Grid>
)

const ErrorStyleWrapper = styled.div<{ $isError: boolean }>`
  & > :first-child {
    ${({ $isError }) =>
      $isError &&
      css`
        border: 1px solid ${({ theme }) => theme.colour.error[600]};

        &:focus {
          border: 1px solid ${({ theme }) => theme.colour.error[600]};
        }
      `}
  }
`
