'use client'

import styled, { css } from 'styled-components'

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: ${theme.widths.m};

    ${theme.media.down('tablet')} {
      max-width: 640px;
    }
  `}
`
