'use client'

import styled, { css } from 'styled-components'
import { Text, Heading, Grid, Link } from '@faml/faml-ui'
import { Trans, useTranslation } from 'react-i18next'
import { Locales } from '@modules/i18n/settings'

const LOCALE_TO_BSI_LOCALE_MAP = {
  'de-DE': 'de-DE',
  'en-AU': 'en-AU',
  // The certificate doesn't exist for en-GB, so we use en-NZ
  'en-GB': 'en-NZ',
  'en-NZ': 'en-NZ',
  'fr-FR': 'fr-FR',
  'es-ES': 'es-ES',
} satisfies Record<Locales, string>

export const SecurityDisclaimer = () => {
  const { t } = useTranslation('global')
  const locale = window.__FAML_FORMS_CONFIG__.LOCALE
  const bsiLocale = locale ? LOCALE_TO_BSI_LOCALE_MAP[locale] : 'en-NZ'

  return (
    <SecurityDisclaimerWrapper>
      <ISOMark
        src="/icons/iso-mark.png"
        alt={t('security_disclaimer_component.mark_image_alt')}
        width={142}
        height={72}
      />
      <SecurityText>
        <Grid rowGap="s">
          <Heading level="4" as="h2">
            {t('security_disclaimer_component.title')}
          </Heading>
          <Text colour="secondary" as="p">
            <Trans i18nKey="security_disclaimer_component.description">
              First AML takes data privacy and security seriously and is{' '}
              <Link
                isExternal
                href={`https://www.bsigroup.com/${bsiLocale}/validate-bsi-issued-certificates/client-directory-profile/FIRST_-0047794548-000`}
              >
                ISO 27001:2013 certified.
              </Link>{' '}
              For more information visit{' '}
              <Link isExternal href="https://www.firstaml.com/security">
                firstaml.com/security.
              </Link>
            </Trans>
          </Text>
        </Grid>
      </SecurityText>
    </SecurityDisclaimerWrapper>
  )
}

const SecurityDisclaimerWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme.brandColours.white};
    padding: ${theme.spacing.l};

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
      flex-direction: row;
      text-align: left;
      padding: ${`${theme.spacing.l} ${theme.spacing.xl} ${theme.spacing.xl}`};
    }
  `}
`

const SecurityText = styled.div`
  ${({ theme }) => css`
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
      text-align: left;
      margin-left: ${theme.spacing.xl};
    }
  `}
`

const ISOMark = styled.img`
  ${({ theme }) => css`
    width: 142px;
    height: 72px;

    margin-bottom: ${theme.spacing.l};

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
      margin-bottom: 0;
    }
  `}
`
