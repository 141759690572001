'use client'

import type { ReactNode } from 'react'
import styled from 'styled-components'
import { Grid, Heading } from '@faml/faml-ui'
import { useTranslation } from 'react-i18next'

type Props = {
  children: ReactNode
}

export const Success = ({ children }: Props) => {
  const { t } = useTranslation('global')

  return (
    <Container rowGap="l" alignX="centre">
      <Image src="/icons/success-page.svg" alt={t('success_component.image_alt')} />
      <Heading level="1" align="centre">
        {t('success_component.title')}
      </Heading>
      {children}
    </Container>
  )
}

const Image = styled.img`
  width: 180px;
  height: 190px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 229px;
    height: 241px;
  }
`

const Container = styled(Grid)`
  margin: auto 0;
`
