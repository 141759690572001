'use client'

import { ClientBranding } from '@api'
import { LoadingSpinner } from '@faml/faml-ui'
import { useState } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  branding?: ClientBranding | null
  clientName?: string
}

export const Header = ({ branding, clientName }: Props) => {
  const logoUrl = branding?.logoUrl
  const [loaded, setLoaded] = useState(false)

  const onLoadImage = () => {
    setLoaded(true)
  }

  return (
    <Container $isLoaded={loaded}>
      {!loaded && <LoadingSpinner isDisplayBlock isWhite />}
      <img
        src={logoUrl ? logoUrl : '/icons/logo.svg'}
        alt={logoUrl && clientName ? clientName : 'First AML'}
        width="auto"
        height={50}
        onLoad={() => {
          onLoadImage()
        }}
      />
    </Container>
  )
}

export const HEADER_HEIGHT = '80px'
const MOBILE_HEADER_HEIGHT = '40px'
const MOBILE_HEADER_LOGO_HEIGHT = '40px'
const TABLET_HEADER_LOGO_HEIGHT = '45px'

const Container = styled.header<{ $isLoaded: boolean }>(
  ({ theme, $isLoaded }) => css`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${HEADER_HEIGHT};
    margin-top: ${theme.spacing.l};
    > img {
      display: ${$isLoaded ? 'block' : 'none'};
    }

    ${theme.media.down('desktop')} {
      margin-top: ${theme.spacing.xxl};
      height: ${MOBILE_HEADER_HEIGHT};
      > img {
        height: ${TABLET_HEADER_LOGO_HEIGHT};
      }
    }

    ${theme.media.down('tablet')} {
      margin-top: ${theme.spacing.l};
      > img {
        height: ${MOBILE_HEADER_LOGO_HEIGHT};
      }
    }
  `
)
