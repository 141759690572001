'use client'

import styled, { css } from 'styled-components'
import { ReactNode } from 'react'
import { Text, Link, Heading, Grid } from '@faml/faml-ui'
import { Trans, useTranslation } from 'react-i18next'

export type ErrorVariant = 'invalid-address' | 'nothing-to-display' | 'expired-token' | 'generic'

interface Props {
  variant: ErrorVariant
  additionalContent?: ReactNode
}

export const ErrorPage = ({ variant, additionalContent }: Props) => {
  const { t } = useTranslation('global')

  return (
    <Grid rowGap="xxl" alignX="centre" alignY="centre" isFullHeight>
      {variant === 'invalid-address' && (
        <Grid rowGap="xxl" alignX="centre">
          <img src="/icons/invalid-address.svg" alt={t('error.invalid_address.image_alt')} />
          <Grid rowGap="m">
            <Heading level="1" align="centre">
              {t('error.invalid_address.title')}
            </Heading>
            <Text size="small" align="centre" as="p">
              <Trans i18nKey="error.invalid_address.description">
                That web address is not one we recognise. Please try clicking the link direct from your email again, or
                visit our <Link href="https://support.firstaml.com">help centre</Link> for support.
              </Trans>
            </Text>
          </Grid>
        </Grid>
      )}
      {variant === 'nothing-to-display' && (
        <Grid rowGap="xxl" alignX="centre">
          <NothingToDisplayImage src="/icons/nothing-to-display.svg" alt={t('error.nothing_to_display.image_alt')} />
          <Grid rowGap="m">
            <Heading level="1" align="centre">
              {t('error.nothing_to_display.title')}
            </Heading>
            <Text size="small" align="centre" as="p">
              {t('error.nothing_to_display.description')}
            </Text>
          </Grid>
        </Grid>
      )}
      {variant === 'expired-token' && (
        <Grid rowGap="xxl" alignX="centre">
          <img src="/icons/link-not-active.svg" alt={t('error.expired_token.image_alt')} />
          <Grid rowGap="m">
            <Heading level="1" align="centre">
              {t('error.expired_token.title')}
            </Heading>
            <Text size="small" align="centre" as="p">
              {t('error.expired_token.description')}
            </Text>
          </Grid>
        </Grid>
      )}
      {/* Setting a default value for generic error page translations so that a default translation is shown in case of translation load fail */}
      {variant === 'generic' && (
        <Grid rowGap="xxl" alignX="centre">
          <GenericImage
            src="/icons/generic-error-image.svg"
            alt={t('error.generic.image_alt', {
              defaultValue: 'Generic error page illustration of two people inspecting a server fault',
            })}
          />
          <Grid rowGap="m">
            <Heading level="2" align="centre">
              {t('error.generic.title', { defaultValue: 'Sorry! Something went wrong' })}
            </Heading>
            <Text size="small" align="centre" as="p">
              {t('error.generic.description', {
                defaultValue: 'An unexpected error has occurred, please try again or contact support.',
              })}
            </Text>
          </Grid>
        </Grid>
      )}
      {additionalContent}
    </Grid>
  )
}

const NothingToDisplayImage = styled.img`
  width: 213px;
  height: 196px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 280px;
    height: 257px;
  }
`
const GenericImage = styled.img(
  ({ theme }) => css`
    ${theme.media.down('tablet')} {
      width: 100%;
    }
  `
)
