'use client'

import { ReactNode } from 'react'
import { Details as DetailsComponent } from '@faml/faml-ui'
import styled, { css } from 'styled-components'

type DetailsVariant = 'success' | 'error' | 'default'

type Props = {
  renderSummary: (isOpen: boolean) => ReactNode
  variant: DetailsVariant
  children: ReactNode
  id: string
}

export const Details = ({ children, variant, renderSummary, id }: Props) => {
  return (
    <DetailsContainer id={id} $variant={variant} summary={renderSummary}>
      <DetailsContent>{children}</DetailsContent>
    </DetailsContainer>
  )
}

const DetailsContainer = styled(DetailsComponent)<{ $variant: DetailsVariant }>`
  ${({ theme, $variant }) => css`
    border-radius: ${theme.borderRadius.m};
    border: 1px solid ${$variant === 'error' ? theme.colour.error[600] : theme.strokeColours.dark};
    background-color: ${$variant === 'success' ? theme.colour.success[25] : theme.brandColours.white};

    &:focus-within {
      border-color: transparent;
      box-shadow: 0 0 0 3px ${$variant === 'error' ? theme.colour.error[600] : theme.colour.success[600]};

      /* Hack for Safari so box shadow does not disappear randomly */
      padding-left: 1px;
      margin-left: -1px;
      padding-right: 1px;
      margin-right: -1px;
    }

    summary {
      display: block;
      padding: ${`${theme.spacing.l} ${theme.spacing.m}`};
      outline: none;
    }
  `}
`

const DetailsContent = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.m} ${theme.spacing.l} ${theme.spacing.m}`};
`
