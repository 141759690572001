'use client'

import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Inline, Link, Text } from '@faml/faml-ui'
import { Trans, useTranslation } from 'react-i18next'

export const Footer = () => {
  // Workaround until env vars approach is changed
  // TODO: refactor this
  const [marketingUrl, setMarketingUrl] = useState('')
  const { t } = useTranslation('global')

  useEffect(() => {
    setMarketingUrl(window.__FAML_FORMS_CONFIG__.MARKETING_SITE_URL)
  }, [])

  return (
    <Container>
      <LinkStyled href={marketingUrl} isExternal aria-label={t('footer.logo_link_aria_label')}>
        <InlineStyled space="s" alignY="centre" alignX="centre">
          <Trans i18nKey="footer.logo_link_text">
            <Text size="small">Powered by</Text>
            <img src="/icons/logo.svg" alt="First AML" width="auto" height={28} />
          </Trans>
        </InlineStyled>
      </LinkStyled>
    </Container>
  )
}

const Container = styled.footer`
  flex-shrink: 0;
  padding-bottom: ${({ theme }) => theme.spacing.l};
  text-align: center;
`

const InlineStyled = styled(Inline)`
  display: inline-flex;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`
